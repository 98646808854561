$(function() {
  $(document).ready(function() {
    $('select').formSelect()

    // this is a hack to make select show interactions similar to input
    $('input.dropdown-trigger').on('click', function(e) {
      $wrapper = $(e.target).closest('div.select-wrapper')
      $wrapper.next().addClass('active')
    })

    $('select').on('change', function(e) {
      $select = $(e.target)
      $div = $select.parent()
      $label = $div.next()

      if($select.val().length > 0) {
        $label.addClass('active')
      }
      else {
        $label.removeClass('active')
      }
    })

    for (let div of Array.from($('div.select-wrapper'))) {
      $(div).children('select').trigger('change');
    }

    $('#covid19-modal').modal()
    $('#covid19-modal').modal('open')

    $('#covid19-carousel').carousel({
      fullWidth: true,
      indicators: true
    })
  })
})